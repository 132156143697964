import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import Services1 from "../assets/images/Services/1.webp";
import Services2 from "../assets/images/Services/2.webp";
import Services3 from "../assets/images/Services/3.webp";
import Services4 from "../assets/images/Services/4.webp";
import Footer1 from "../assets/images/Home/7.webp";




const Services = () => (
    <Layout>
        <SEO title="Services" />
        <Navbar />
        <div className="page-title-area">
            <img className="imageStyle" src={Services1} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Services2} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Services3} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Services4} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Footer1} alt="image" />
        </div>
    </Layout>
)

export default Services;
